export default function (data, component, title = 'Relatório', orientation = 'landscape', formats = {}) {
    let window = `relatorio.gerencial.view`
    !this.$uloc.printView.get(window) && this.$uloc.printView.new({
        wid: window,
        title: title,
        windowClass: 'erp-print',
        contentClass: '',
        props: {
            data: data,
            orientation: orientation,
            excel: formats.excel
        }
    }, component)
        .then((wid) => {
            this.$uloc.printView.listen(wid, {
                excel: (wid, val) => {
                    console.log('Excel click')
                    this.load && this.load('excel')
                }
            })
        }) // return wid
}
